import './App.css'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"
import Header from './components/Header'
import Test from './components/Test'
import Login from './components/Login'
import Register from './components/Register'
import Controls from './components/Controls'
import Content from './components/Content'
import Footer from './components/Footer'

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route exact path="/" element={<Content />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/test" element={<Test />} />
          <Route path="/controls" element={<Controls />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  )
}

export default App
