import { useEffect, useState } from "react"
// import { Link, useNavigate } from "react-router-dom"
import { useNavigate } from "react-router-dom"
// import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../firebase"
import { auth, logInWithEmailAndPassword } from "../firebase"
import { useAuthState } from "react-firebase-hooks/auth"

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      console.log('Loading...')
      return
    }
    if (user) navigate('/controls');
  }, [user, loading, navigate])

  return (
    <div className="login">
      <div className="login-container">
        <input
          type="text"
          className="login-textbox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail address"
        />
        <input
          type="password"
          className="login-textbox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button
          className="content-button"
          onClick={() => logInWithEmailAndPassword(email, password)}
        >
          Login
        </button>
        {/* <button className="content-button login-google" onClick={signInWithGoogle}>
          Login with Google
        </button>
        <div>
          <Link to="/reset">Забули пароль?</Link>
        </div>
        <div>
          Не маєте обліковки? <Link to="/register">Зареєструватись</Link> зараз.
        </div> */}
      </div>
    </div>
  )
}