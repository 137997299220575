import { useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { Link, useNavigate } from "react-router-dom"
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "../firebase"

const Register = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()

  const register = () => {
    if (!name) alert("Please enter name")
    registerWithEmailAndPassword(name, email, password)
  };

  useEffect(() => {
    if (loading) return
    if (user) navigate('/controls')
  })

  return (
    <div className="login">
      <div className="login-container">
        <input
          type="text"
          className="login-textbox"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Full Name"
        />
        <input
          type="text"
          className="login-textbox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="login-textbox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button className="content-button" onClick={register}>
          Зареєструватись
        </button>
        <button
          className="content-button login-google"
          onClick={signInWithGoogle}
        >
          Зареєструватись Гуглом
        </button>
        <div>
          Вже є обліковка? <Link to="/login">Увійти</Link> зараз.
        </div>
      </div>
    </div>
  )
}
export default Register