import logo from "../clown.jpg"
// import {Link} from "react-router-dom"

const Header = () => {
  return (
    <header>
      <img src={logo} className="header-img" alt="Dasrsy" />
      <h2 className="header-title">Це все про нього</h2>
      {/* <ul className="header-list">
        <li><Link to="/">Головна</Link></li>
        <li><Link to="/login">Увійти</Link></li>
        <li><Link to="/test">Експеримент</Link></li>
      </ul> */}
    </header>
  )
 }

 export default Header