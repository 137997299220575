import { useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate } from "react-router-dom"
import { auth, db, logout } from "../firebase"
import { query, collection, getDocs, where } from "firebase/firestore"

const Controls = () => {
  const [user, loading] = useAuthState(auth)
  const [name, setName] = useState("")
  const navigate = useNavigate()

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid))
      const doc = await getDocs(q)
      const data = doc.docs[0].data()
      setName(data.name)
    } catch (err) {
      console.error(err)
      alert("An error occured while fetching user data")
    }
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/")
    fetchUserName()
  })

  return (
    <div className="login">
      <div className="login-container">
        <div>Ви увійшли як</div>
        <div>{name}</div>
        <div>{user?.email}</div>
        <button className="content-button" onClick={logout}>
          Вийти
        </button>
      </div>
    </div>
  )
}

export default Controls