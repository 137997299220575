import { initializeApp } from "firebase/app"
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut
} from "firebase/auth"
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  Timestamp
} from "firebase/firestore"
import {
  initializeAppCheck,
  ReCaptchaV3Provider,
  getToken
} from "firebase/app-check"

const firebaseConfig = {
  apiKey: "AIzaSyDfF-F977iduIfwtD9auLiGBkqZug7DM6A",
  authDomain: "pogolos.firebaseapp.com",
  projectId: "pogolos",
  storageBucket: "pogolos.appspot.com",
  messagingSenderId: "507060383402",
  appId: "1:507060383402:web:78c0b1036b1f04d514ead3",
  measurementId: "G-RV54D3JSYW"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)

const googleProvider = new GoogleAuthProvider()

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider)
    const user = res.user
    const q = query(collection(db, "users"), where("uid", "==", user.uid))
    const docs = await getDocs(q)
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      })
    }
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    })
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email)
    alert("Password reset link sent!")
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

const logout = () => {
  signOut(auth)
}

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LepHcEgAAAAAN9aNaLPHtQIQRh9QBYsAwHEewxn'),
  isTokenAutoRefreshEnabled: true
})

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  appCheck,
  getToken,
  Timestamp
}