const sitesData = [
  {
    id: 1,
    name: 'вовалісапет',
    url: 'https://chess.com',
    rating: 5,
    show: true,
    new: true
  },
  {
    id: 2,
    name: 'віслюк оманський',
    url: 'https://lichess.org',
    rating: 4.5,
    show: true
  },
  {
    id: 3,
    name: 'бідоносець',
    url: 'https://lichess.org',
    rating: 4.5,
    show: true,
    new: true
  },
  {
    id: 4,
    name: 'шмаркля',
    url: 'https://lichess.org',
    rating: 4.5,
    show: true
  },
  {
    id: 5,
    name: 'нелох',
    url: 'https://lichess.org',
    rating: 4.5,
    show: true
  },
  {
    id: 6,
    name: 'вава',
    url: 'https://lichess.org',
    rating: 4.5,
    show: true,
    new: true
  }
]

export default sitesData