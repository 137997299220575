import { useState, useEffect } from "react"
import sitesData from "../admin/sitesData"

const Test = () => {

  const [randName, setRandName] = useState('')

  const [clowns, setClowns] = useState({id: '', url: '', author: ''})

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    passwordRepeat: "",
    emailSub: false
  })

  const handleForm = (event) => {
    const {name, type, value, checked} = event.target
    setFormData(prev => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value
    }))
  } 

  const submitForm = (event) => {
    event.preventDefault()
    formData.password === formData.passwordRepeat ? console.log('passwords match') : console.log("passwords don't match")
    if (formData.emailSub) console.log("Thanks for sub")
    console.log('submitted')
  }

  useEffect(() => {
    const getClowns = async () => {
      console.log('Effect fired')
      // fetch("https://imsea.herokuapp.com/api/1?q=clown", {mode: 'no-cors'})
      const res = await fetch("https://picsum.photos/v2/list")
      const data = await res.json()
      // console.log(data)
      if (data) setClowns(prev => {
        let rand
        while (true) {
          rand = Math.floor(data.length * Math.random())
          // if (prev !== data.results[rand]) return data.results[rand]
          if (prev.id !== data[rand].id) {
           const {id, download_url: url, author} = data[rand]
           return {id, url, author}
          }
        }
      })
    }
    getClowns()
  }, [randName])

  const getRandomSite = () => {
    setRandName(prev => {
      let rand
      while (true) {
        rand = Math.floor(sitesData.length * Math.random())
        // console.log(rand)
        if (prev !== sitesData[rand].name) return sitesData[rand].name
      }
    })
  }

  return (
    <div>
      <div className="meme">
      <button onClick={getRandomSite}>Натисніть для випадкового мему</button>
      <div className="memeText">{randName}</div>
      <img className="memeImage" src={clowns.url} alt={randName} />
      </div>
      <form onSubmit={submitForm} className="content-form">
        <input type="text" name="username" onChange={handleForm} value={formData.username} />
        <input type="email" name="email" onChange={handleForm} value={formData.email} />
        <input type="password" name="password" onChange={handleForm} value={formData.password} />
        <input type="password" name="passwordRepeat" onChange={handleForm} value={formData.passwordRepeat} />
        <input type="checkbox" name="emailSub" onChange={handleForm} value={formData.emailSub} />
        <button>Submit</button>
      </form>
      <div>
        {
          4 < 5 ? <span style={{color: 'blue'}}>less than five</span> : <span style={{color: 'red'}}>five {'exactly'}</span>
        }
      </div>
    </div>
  )
}

export default Test