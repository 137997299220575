// import {useState} from "react"

const Moniker = (props) => {
  let url
  let badge

  // const [count, setCount] = useState(props.rating)
  // const [show, setShow] = useState(props.show)

  url = props.url && <div className="moniker-url"><a href={`https://${props.url}`}>джерело</a></div>
  badge = props.new && <div className="moniker-badge">new</div>

  // const plusOne = () => {
  //   setCount(prev => prev + 1)
  // }
  // const minusOne = () => {
  //   setCount(prev => (prev > 0) ? prev - 1 : 0)
  // }
// style={show ? {} : {border: "2px dashed"}}>
  if (props.show || props.user) 
    return (
      <div className={!props.show ? 'moniker-card moniker-card-hidden' : 'moniker-card'}>
        {badge}
        {props.user && <div className="moniker-show" onClick={() => props.updating ? console.log('Wait for the operation to finish') : props.toggle(props.id)}>X</div>}
        <div className="moniker-name">{props.name.toUpperCase()}</div>
        {url}
        <div className="moniker-vote">
          <button onClick={() => props.updating ? console.log('Wait for the operation to finish') : props.vote(props.id, 1)}>+</button> {props.rating} <button onClick={() => props.updating ? console.log('Wait for the operation to finish') : props.vote(props.id, -1)}>–</button>
        </div>
        {props.updating && !props.error && <div className="moniker-updating">Зачекайте...</div>}
        {props.error && <div className="moniker-error">{props.error}</div>}
      </div>
    )
}

export default Moniker