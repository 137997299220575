import Moniker from "./Moniker"
import {useState, useEffect} from "react"
import {useAuthState} from "react-firebase-hooks/auth"
import {auth, appCheck, getToken, Timestamp} from "../firebase"
import {JSEncrypt} from "jsencrypt"
const API_URI = (process.env.NODE_ENV === 'production') ? 'https://pogolos.onrender.com/' : 'http://localhost:6060/'
// const API_URI = (process.env.NODE_ENV === 'production') ? 'https://arcane-badlands-59196.herokuapp.com/' : 'http://192.168.0.107:6060/'

const Content = () => {

  const encrypt = (text) => {
    const jsEncrypt = new JSEncrypt()
    const publicKey = `-----BEGIN PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAs7zrMMSemyI2OPQWnP3a
    a30USLXYDdmjIqIgYhBwMomKJgOGiBILayY8I+6AcLSdK3XTqEL5WDJx8DOYJLPH
    Kl7gqaMKtFDHguNOZAOxuVffEABZOdf6k34qYo+PQ/z8v6Hs7KyVhPSkQE40G8Dh
    VbJPExN0QTnFWlx6KllE43SY7FPQmyWAQoBIZ4f1IA6n2f+SrcJBHPTBrYGK4g3j
    LqJvM8O/4PswAozjmtXgbg8vye+bCkOaQeFuSfHkHGIJVDSBkvWw5F+omIO1taPl
    MA/jWDPFUVd0FBjEz2T3w0m8C0vWfPWpCoFfX/kAigjyc8jNJ0A5S+WzqPq7Xlmn
    FQIDAQAB
    -----END PUBLIC KEY-----`
    // console.log('text', text)
    jsEncrypt.setPublicKey(publicKey)
    // console.log('jsEncrypt', jsEncrypt)
    return jsEncrypt.encrypt(text)
  }

  const [user] = useAuthState(auth)
  // console.log([user])
  const [monnikers, setMonnikers] = useState([])
  const [queryMoniker, setQueryMoniker] = useState({})
  const [newMoniker, setNewMoniker] = useState({})
  const [queryError, setQueryError] = useState({})
  const [updateId, setUpdateId] = useState('')
  const [ip, setIP] = useState('')
  const [form, setForm] = useState({name: '', url: ''})
  const [formStatus, setFormStatus] = useState('')
  const [formLock, setformLock] = useState(false)

  useEffect(() => {
    const bearer = encrypt(Timestamp.now().seconds.toString())
    // console.log('Bearer', bearer)
    // console.log('DB timestamp', Timestamp.now().seconds)

    const getMonnikers = async () => {
      try {
        console.log('Getting monnikers...')
        const appCheckTokenResponse = await getToken(appCheck)
        const res = await fetch(API_URI + 'monnikers', {
          headers: {
            'Authorization': `Bearer ${bearer}`,
            'X-Firebase-AppCheck': appCheckTokenResponse.token
          }
        })
        const data = await res.json()
        // console.log('Monnikers', data)
        if (data) setMonnikers(data)
      } catch (error) {
        console.log('Error', error)
      }
    }

    const getData = async () => {
      const res = await fetch('https://ipapi.co/json/')
      const data = await res.json()
      // console.log('Geo', data)
      setIP(data.ip)
    }

    getMonnikers()
    getData()
  }, [])

  useEffect(() => {
    if (Object.keys(newMoniker).length !== 0) {
      const bearer = encrypt(Timestamp.now().seconds.toString())
      const addMoniker = async () => {
        try {
          setformLock(true)
          console.log('Adding moniker...')
          const appCheckTokenResponse = await getToken(appCheck)
          const res = await fetch(API_URI + 'moniker', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${bearer}`,
              'X-Firebase-AppCheck': appCheckTokenResponse.token
            },
            body: JSON.stringify(newMoniker)
          })
          const data = await res.json()
          if (data.id) {
            setMonnikers(prev => [...prev, data])
            setFormStatus('Дякую, дуже дякую! Надішлете ще?')
            setTimeout(() => setFormStatus(''), 2000)
          }
          else if (res.status === 409) {
            setFormStatus('Таке прізвисько вже є у базі')
            setTimeout(() => setFormStatus(''), 2000)
          }
          else 
           console.log('API error', data.error)
          setformLock(false)
        } catch (error) {
          console.log('Error', error)
          setFormStatus('Шкода, але сталася помилка')
          setTimeout(() => setFormStatus(''), 2000)
          setformLock(false)
        }
      }
      addMoniker()
    }
  }, [newMoniker])

  useEffect(() => {
    if (Object.keys(queryMoniker).length !== 0) {
      const bearer = encrypt(Timestamp.now().seconds.toString())
      const updateMoniker = async () => {
        try {
          setUpdateId(queryMoniker.id)
          setQueryError({})
          console.log('Updating monnikers...')
          const appCheckTokenResponse = await getToken(appCheck)
          const {id, ...restMoniker} = queryMoniker
          // console.log('queryMoniker', queryMoniker)
          // console.log('restMoniker', JSON.stringify(restMoniker))
          const res = await fetch(API_URI + 'moniker/' + queryMoniker.id, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${bearer}`,
              'X-Firebase-AppCheck': appCheckTokenResponse.token
            },
            body: JSON.stringify(restMoniker)
          })
          const data = await res.json()
          if (data.id) {
            // console.log('updatedMoniker', updatedMoniker)
            setMonnikers(prev => prev.map(moniker => (queryMoniker.id === moniker.id) ? {...data, id: moniker.id} : moniker))
          }
          else 
            console.log('API error', data.error)
          setUpdateId('')
        } catch (error) {
          console.log('Error', error)
          setQueryError({id: queryMoniker.id, error: 'Помилка оновлення'})
          setUpdateId('')
        }
      }
      updateMoniker()
    }
  }, [queryMoniker])

  const toggle = (id) => {
    setQueryMoniker(() => {
      // console.log('prev', prev)
      const moniker = monnikers.find(moniker => moniker.id === id)
      console.log('Toggle moniker...')
      return {...moniker, show: !moniker.show}
      // arr[0].show = false
      // for (let el of arr) {
      //   if (el.id === id) {
      //     el.show = !el.show
      //     break
      //   }
      // }
    })
  }

  const vote = (id, num) => {
    setQueryMoniker(() => {
      const moniker = monnikers.find(moniker => moniker.id === id)
      console.log('Voting for moniker with', num)
      const newRating = moniker.rating + num 
      if (newRating > -1) {
        return {...moniker, votes: {ip: ip, vote: num}}
       }
       else {
        setQueryError({id: id, error: 'Не можна голосувати у мінус'})
        // sleep(1000)
        // console.log(queryError)
        // setQueryError({})
        return {}
       }
    })
  }

  const handleForm = (event) => {
    // console.log(event)
    const {name, type, value, checked} = event.target
    setForm(prev => {
      // console.log(checked)
      return {...prev, [name]: type === 'checkbox' ? checked : value}
    })
  }
  
  const formSubmit = (event) => {
    event.preventDefault()
    // console.log(event)
    if (!formLock && form.name) {
      setNewMoniker(form)
      setForm(() => {
        return {name: '', url: ''}
      })
    }
    // console.log('Form', form)
  }

  const monnikersList = monnikers.map(moniker => <Moniker key={moniker.id} toggle={toggle} vote={vote} user={user} updating={(moniker.id === updateId) ? true : false} error={ (moniker.id === queryError.id) ? queryError.error : ''} {...moniker}/>)

  // useEffect(() => {
  //   getRandomSite()
  // }, [])

  return (
    <main>
      <h1 className='content-header'>Це таємна сторінка про публічну людину</h1>
      <div>Вгадайте хто:</div>
      <ul>
        <li>Обраний народом</li>
        <li>Суцільний нездара</li>
        <li>Популярний за кордоном</li>
      </ul>
      <h2 className="content-subheader">Так ласкаво його звуть співвітчизники</h2>
      <div>Ви можете проголосувати за будь-які варіанти на власний розсуд, але кожне прізвисько не може отримати від вас більше ніж +1 чи менше ніж -1.</div>
      <section className="content-monnikers">
        {monnikersList.length === 0 ? 'Завантажується список прізвиськ...' : monnikersList}
      </section>
      <h2 className="content-subheader">Додати ще</h2>
      <div>Повідомте нове прізвисько, воно з'явиться після перевірки модератором.</div>
        <form className="content-form" onSubmit={formSubmit}>
          <input className="content-textbox" type="text" name="name" onChange={handleForm} value={form.name} placeholder="прізвисько" />
          <input className="content-textbox" type="text" name="url" onChange={handleForm} value={form.url} placeholder="посилання-джерело, за бажанням" />
          {/* <input type="checkbox" name="publish" id="priority" onChange={handleForm} checked={form.publish} />
          <label htmlFor="priority">публікувати</label> */}
          <div>
            <button className="content-button">{!formLock ? 'Чув таке 🙊' : 'Надсилаю ⏳'}</button>
            {formStatus && <div className="content-status">{formStatus}</div>}
          </div>
        </form>
    </main>
  )
}

export default Content